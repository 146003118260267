export const GET_POST_ADMIN = "getPostAdmin";
export const GET_COURSE_ADMIN = "getCourseAdmin";
export const GET_STUDENT_ACCOUNT = "getStudentAccount";
export const GET_USER_IMAGE = "getUserImage";
export const GET_USER_ME_ACCOUNT = "getUserMeAccount";
export const GET_CURRENCY_EXCHANGE_RATE_ADMIN = "getCurrencyExchangeRateAdmin";
export const GET_EXPERTISE_ADMIN = "getExpertiseAdmin";
export const GET_FEEDBACK_ADMIN = "getFeedbackAdmin";
export const GET_GIFT_FOR_TEACHER_ADMIN = "getGiftForTeacherAdmin";

export const GET_COURSE_PAYER = "getCoursePayer";
export const GET_COURSE_FEEDBACK_PAYER = "getCourseFeedbackPayer";
export const GET_PACKAGE_PAYER = "getPackagePayer";
export const GET_PAYMENT_BY_PAYER_PAYER = "getPaymentByPayerPayer";
export const GET_PAYMENT_BY_PAYER_SECOND_PAYER = "getPaymentByPayerSecondPayer";
export const GET_SESSION_PAYER = "getSessionPayer";
export const GET_WORKSHOP_PAYER = "getWorkshopPayer";
export const GET_SESSION_PAYMENT_PAYER = "getSessionPaymentPayer";
export const GET_UNPAID_SESSION_MAIL_LOG_PAYER = "getUnpaidSessionMailLogPayer";
export const GET_UNPAID_SESSION_MAIL_LOG_ARCHIVED_PAYER = "getUnpaidSessionMailLogArchivedPayer";
export const GET_INTRO_MEETING_MAIL_LOG_PAYER = "getIntroMeetingMailLogPayer";
export const GET_INTRO_MEETING_MAIL_LOG_ARCHIVED_PAYER = "getIntroMeetingMailLogArchivedPayer";
export const GET_FEEDBACK_REQUEST_MAIL_LOG_PAYER = "getFeedbackRequestMailLogPayer";
export const GET_FEEDBACK_REQUEST_MAIL_LOG_ARCHIVED_PAYER = "getFeedbackRequestMailLogArchivedPayer";
export const GET_DASHBOARD_GENERAL_MAIL_LOG_PAYER = "getDashboardGeneralMailLogPayer";
export const GET_DASHBOARD_GENERAL_MAIL_LOG_ARCHIVED_PAYER = "getDashboardGeneralMailLogArchivedPayer";
